import React, { useEffect, useRef } from 'react'
import { Link } from 'gatsby'

import * as styles from './Header.module.scss'
import Navigation from './Navigation/Navigation'
import Breadcrumbs from './../UI/Breadcrumbs/Breadcrumbs'

import PreciseLogo from './../../images/logos/Precise_Logo2.inline.svg'
// import PreciseLogo from './../../images/logos/Precise_Logo_cancerSupport.inline.svg'
import CallToActionButton from '../UI/Buttons/CallToActionButton'

const Header = ({ pageData, pageType, mobileNavHandler, activeMobileNav }) => {
  const heroImg = useRef(null)

  useEffect(() => {
    if (pageType === 'home' && heroImg) {
      heroImg.current.className = `${styles.hero} ${styles.home_heroImg}`
    } else if (
      pageData.title1 === 'Technical' &&
      pageData.title2 === 'Services'
    ) {
      heroImg.current.className = `${styles.hero} ${styles.techServices_heroImg}`
    } else {
      let randomNum = Math.floor(Math.random() * 8) + 1
      let type = `interior_heroImg${randomNum}`
      heroImg.current.className = `${styles.hero} ${styles[`${type}`]}`
    }
  }, [pageType, pageData])

  //skip to main content button <a class=”visually-hidden” href=”#mainContent”>Skip to main content</a>

  return (
    <header>
      <div ref={heroImg} className={`${styles.hero}`}>
        <article className={styles.header__container}>
          <section className={styles.header__logoBar}>
            <Link
              aria-label="Link Home"
              to="/"
              className={styles.header__logoWrap}
            >
              <PreciseLogo
                className={styles.header__logo}
                alt="Precise Systems Inc Logo"
              />
            </Link>
            <Navigation
              mobileNavHandler={mobileNavHandler}
              activeMobileNav={activeMobileNav}
            />
          </section>
          <section className={styles.header__titleBar}>
            {pageType === 'interior' ? (
              <section className={styles.header__breadcrumbs}>
                <Breadcrumbs breadcrumbs={pageData.breadcrumbs} />
              </section>
            ) : (
              ''
            )}
            {pageType === 'home' ? (
              <h1 className={`${styles.header__title_home}`}>
                Exceptional Service,{' '}
                <span>
                  <span>Precise</span> Solutions
                </span>
              </h1>
            ) : (
              <h1>
                {pageData.title1} <span>{pageData.title2}</span>
              </h1>
            )}
            {pageData.ctaButton ? (
              <CallToActionButton
                type="white"
                buttonData={pageData.ctaButtonData}
              />
            ) : (
              ''
            )}
          </section>
        </article>
        <svg
          className={styles.header__svgBox}
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon className={styles.basesvg} points="30,100 0,25 0,100" />
          <polygon className={styles.basesvg} points="25,100 100,100 100, 20" />
          <polygon className={styles.graysvg} points="30,100 30,90 0,20 0,30" />
          <polygon
            className={styles.graysvg}
            points="30,90 30,100 100,30 100, 0"
          />
        </svg>
      </div>
    </header>
  )
}

export default Header
